
import axios from 'axios'
import { BatchImageSet, flattenBatch } from '@/models/job'
import { Component, Prop } from 'vue-property-decorator'
import { approveBatch, Type, ApproveType } from '@/approve'
import { updateBigQuery } from './../batchStats'
import Base from '@/pages/JobBatchBase.vue'
import { setBatchComplete, setBatchAwaitingImages, setBatchReady, setBatchOnHold, ErrorTypes, unlockReadonly, canUnlockReadonly, setBatchErrored } from '@/batchList'
import { SQLBatch, Batch, BatchState } from '@ht-lib/preproduction-models'
import { batches } from '@/firebase'
import { flagForEditing } from '@/flag'

@Component
export default class extends Base {
  @Prop() batch!: number
  unsubscribe: (() => void) | undefined = undefined
  batchData!: SQLBatch

  created () {
    const batchInFirestore = this.$route.params.batchInFireStore === '1'

    this.type = Type.Batch
    this.dataFn = async () => {
      try {
        const url = `${ process.env.VUE_APP_BATCH_API }/batch/${ this.batch }/images`
        const key = process.env.VUE_APP_BATCH_API_KEY
        const set = await axios.get<BatchImageSet>(url, { params: { key } })
        this.jobImages = flattenBatch(set.data)
        this.batchData = set.data
        this.toolbarTitle = `B-${ this.batchData.id || '' } | ${ this.batchData.jobType || '' } | ${ this.batchData.jobSubType || '' } | Images: ${ this.batchData.headCount || 0 }`
      } catch (e) {
        await setBatchErrored(this.batch)
        throw e
      }
    }

    this.flagFn = (longRefs, flagText) => {
      console.log('Flagging batch images: ', longRefs, 'for edit')
      return flagForEditing(longRefs, flagText, this.batchData.market, this.batch)
    }

    this.closeFn = async () => {
      if (batchInFirestore) {
        try {
          await setBatchReady(this.batch)
        } catch (e) {
          // Allow user to close regardless
          if (e !== ErrorTypes.BatchOperationDenied) throw e
        }
      }
      this.$router.push('/')
    }

    const initialSaveFn = this.saveFn
    this.saveFn = async () => {
      try {
        const result = await initialSaveFn()
        if (batchInFirestore && result.success) {
          await setBatchOnHold(this.batch, new Date().getTime() - this.timeStarted)
        }
        return result
      } catch (e) {
        await setBatchErrored(this.batch)
        return { success: false, message: e }
      }
    }

    this.approveFn = async () => {
      try {
        const timeFinished = new Date()
        let timeInQA = (timeFinished.getTime() - this.timeStarted)
        const approval = await approveBatch(this.adjustedImages, this.batch)
        if (batchInFirestore && (approval.type === ApproveType.PARTIAL || approval.type === ApproveType.SUCCESS)) {
          const batchRef = await setBatchComplete(this.batch)

          // Set batch complete will throw an error if the batch did not set to complete successfully.
          const batch = batchRef.data() as Batch
          timeInQA = batch.timeCache ? timeInQA + batch.timeCache : timeInQA

          await updateBigQuery(this.batchData, this.adjustedImages, timeInQA, new Date(timeFinished).toISOString())
        }
        return approval
      } catch (e) {
        await setBatchErrored(this.batch)
        return { message: e, type: ApproveType.FAILED }
      }
    }

    this.imageNotFoundFn = async () => {
      if (batchInFirestore) {
        await setBatchAwaitingImages(this.batch)
      }
    }

    this.unsubscribe = batches.doc(this.batch.toString())
      .onSnapshot(snapshot => {
        if (snapshot.metadata.fromCache) return

        const batch = snapshot.data() as Batch
        if ((batch.user === undefined || batch.user.uid !== this.$store.state.profile.uid) && batch.state !== BatchState.COMPLETE) {
          this.$q.dialog({
            title: 'Alert',
            message: 'You have been unassigned from this job',
            persistent: true
          }).onOk(() => { this.$router.push('/') })
        }
      })

    if (this.readOnly = this.$route.params.readOnly === '1') {
      const message = 'You are currently viewing this batch in a READ ONLY mode'
      if (canUnlockReadonly()) {
        this.updateBanner('info', message, {
          label: 'Unlock',
          fn: async () => {
            await unlockReadonly(this.batch)
            this.readOnly = false
          }
        })
      } else {
        this.updateBanner('info', message)
      }
    }

    this.loadData()
  }

  beforeDestroy () {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}
