import store from '@/store'
import axios, { AxiosResponse } from 'axios'

import { Batch, SQLBatch } from '@ht-lib/preproduction-models'
import { DRGB } from './cccolor'
import { batches } from './firebase'
import { CorrectionImage } from './models/image'

interface Row {
  batchId: number
  dateCreated: string
  dateCompleted: string
  timeInQueue: number
  timeInQA: number
  images: number
  ccAdjusted: number
  rgbAdjusted: number
  jobTypeId: number
  jobSubTypeId: number
  userId: string
  userName: string
}

export async function updateBigQuery (sqlBatch: SQLBatch, images: CorrectionImage[], timeInQA: number, dateCompleted: string): Promise<AxiosResponse<void>> {
  const url = `${ process.env.VUE_APP_CC_API }/api/contrastcorrection/approve/batch`
  const batch = await batches.doc(sqlBatch.id.toString()).get()

  const data = batch.data() as Batch
  let timeInQueue = -1
  if (data && data.timeAdded) {
    timeInQueue = new Date().getTime() - data.timeAdded
  }

  let ccAdjusted = 0
  let rgbAdjusted = 0
  images.forEach(img => {
    if (!DRGB.rgbEqual(img.actual, img.initial)) rgbAdjusted++
    if (img.actual.D !== img.initial.D) ccAdjusted++
  })

  const { uid, displayName } = store.state.profile
  const postData: Row = {
    batchId: sqlBatch.id,
    images: sqlBatch.headCount,
    ccAdjusted,
    rgbAdjusted,
    dateCreated: sqlBatch.date,
    dateCompleted,
    userName: displayName || 'None',
    userId: uid,
    jobSubTypeId: sqlBatch.jobSubTypeId || -1,
    jobTypeId: sqlBatch.jobTypeId || -1,
    timeInQA,
    timeInQueue
  }

  console.log(JSON.stringify(postData))
  return axios.post(url, postData, {
    params: {
      key: process.env.VUE_APP_API_KEY
    }
  })
}
